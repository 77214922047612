import type { AppProps } from 'next/app';
import { ToastContainer } from 'react-toastify';
import { ParallaxProvider } from 'react-scroll-parallax';
import Script from 'next/script';
import { Layout } from '../components/layout/layout.comp';
import { GlobalStyles } from '../styles/styles.config';
import Head from 'next/head';

import 'react-toastify/dist/ReactToastify.css';

export default function App({ Component, pageProps }: AppProps) {
	return (
		<>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Head>
			{/* GTM */}
			<Script
				id="gtm-script"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-WJGB8PP');`,
				}}
			></Script>
			{/* Ref, source, etc */}
			<Script
				id="ref-script"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					// Get referrer, source, UTM parameters, etc and store in cookie
					__html: `
            (function() {
              // Function to check if a specific cookie exists
              function doesCookieExist(name) {
                  return document.cookie.split(';').some(function(item) {
                      return item.trim().indexOf(name + '=') == 0;
                  });
              }
          
              // Function to create a flat object from URLSearchParams
              function getQueryParams() {
                  var params = new URLSearchParams(window.location.search);
                  var queryParams = {};
                  for (const [key, value] of params.entries()) {
                      queryParams[key] = value;
                  }
                  return queryParams;
              }
          
              // Check if the 'cn-u-d' cookie already exists
              if (!doesCookieExist('cn-u-d')) {
                  // Get the referrer
                  var referrer = document.referrer || 'direct';
          
                  // Get the query parameters
                  var queryParams = getQueryParams();
          
                  // Create a flat object with referrer and query parameters
                  var userData = Object.assign({ referrer: referrer, 'landing-page': (window.location.href || '').split('?')[0] }, queryParams);
          
                  // Store the object in a cookie named 'cn-u-d'
                  document.cookie = "cn-u-d=" + encodeURIComponent(JSON.stringify(userData)) + ";path=/;";
              }
            })();
          `,
				}}
			/>
			{/* <Script
        id="profitwell-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          try {
            profitwell('start', {});
          } catch(e) {}`,
        }}
      ></Script> */}
			<GlobalStyles />
			<ParallaxProvider>
				<Layout>
					<Component {...pageProps} />
					<ToastContainer />
				</Layout>
			</ParallaxProvider>
		</>
	);
}
